/*定义数据字典常量字典 Map<String,List<{value:label}>*/
const dictListMap = {
    ocean_warehouse_type:[
        {value:'1',label:'自营仓'},
        {value:'2',label:'合作代理仓'}
    ],
    ocean_country:[
        {value:'美国',label:'美国'},
        {value:'英国',label:'英国'},
        {value:'德国',label:'德国'},
        {value:'法国',label:'法国'},
        {value:'意大利',label:'意大利'},
        {value:'西班牙',label:'西班牙'},
        {value:'日本',label:'日本'},
        {value:'澳洲',label:'澳洲'},
        {value:'其他',label:'其他'}
    ],
    unit_whether:[
        {value:'1',label:'是'},
        {value:'0',label:'否'}
    ],
    config_type:[
        {value:'1',label:'海运'},
        {value:'2',label:'快递'}
    ],
    unit_enable:[
        {value:'1',label:'可选'},
        {value:'0',label:'不可选'}
    ],

    unit_normal:[
        {value:'1',label:'正常'},
        {value:'0',label:'不正常'}
    ],
    ac_fee_config_type:[
        {value:'ExpensesBasicInfo',label:'费用类-自动录入'},
        {value:'FeeSyncBasicInfo',label:'费用类-自动同步'},
    ],
    sale_config_dict_rule_type:[
        {value:'1',label:'基本'},
        {value:'2',label:'数据字典'},
        {value:'3',label:'字符串'},
        {value:'4',label:'整数'},
        {value:'5',label:'数字'},
        {value:'6',label:'订单集合'},
        {value:'7',label:'时间类型'},

    ],
    sale_config_dict_order_type:[
        {value:'1',label:'海运'},
        {value:'2',label:'快递'},
    ],
    delivery_order_status:[
        {value:'0',label:'正常'},
        {value:'1',label:'手动扣货'},
        {value:'2',label:'自动扣货'},
        {value:'3',label:'手动放货'},
        {value:'4',label:'自动放货'},
        {value:'5',label:'无循环订单自动扣货'},
        {value:'6',label:'无循环订单自动放货'},
    ],
    volume_large_info:[
        {value:'超63.5cm||22kg',label:'超63.5cm||22kg'},
        {value:'超124cm',label:'超124cm'},
        {value:'超150cm',label:'超150cm'},
        {value:'超200cm',label:'超200cm'},
    ],
    volume_status:[
        {value:'0',label:'正常'},
        {value:'1',label:'超长'},
        {value:'2',label:'超重'},
        {value:'3',label:'超长超重'},
    ],
    lose_reason_handle_type:[
        {value:'个人原因',label:'个人原因'},
        {value:'非个人原因',label:'非个人原因'},
    ],
    bd_country_hs_code:[
        {value:'9903.88.15',label:'9903.88.15',rate:'7.5%',wenben:'301-15'},
        {value:'9903.88.01',label:'9903.88.01',rate:'25%',wenben:'301-1'},
        {value:'9903.88.02',label:'9903.88.02',rate:'25%',wenben:'301-2'},
        {value:'9903.88.03',label:'9903.88.03',rate:'25%',wenben:'301-3'},
        {value:'9903.88.04',label:'9903.88.04',rate:'25%',wenben:'301-4'},
        // {value:'9903.88.08',label:'9903.88.08',rate:'25%',wenben:'301-8'},
        // {value:'9903.88.42',label:'9903.88.42',rate:'25%',wenben:'301-42'},
        // {value:'9903.88.66',label:'9903.88.66',rate:'25%',wenben:'301-66'},
        {value:'9903.88.67',label:'9903.88.67',rate:'0%',wenben:'301-67'},
        {value:'9903.88.68',label:'9903.88.68',rate:'0%',wenben:'301-68'},
    ],
    // hs_code_301:[
    //     {value:'301-15',label:'301-15'},
    //     {value:'301-1',label:'301-1'},
    //     {value:'301-2',label:'301-2'},
    //     {value:'301-3',label:'301-3'},
    //     {value:'301-4',label:'301-4'},
    //     // {value:'301-8',label:'301-8'},
    //     // {value:'301-42',label:'301-42'},
    //     // {value:'301-66',label:'301-66'},
    //     {value:'301-67',label:'301-67'},
    //     {value:'301-68',label:'301-68'},
    // ]
    remote_download_jasper_url : '/forest/file/downloadBaseJasper',
    remote_test_download_jasper_url : '/forest/file/downloadTestBaseJasper',
//     销售报表的默认下拉框
    profit_statement_time_type : [
        { value: 'year', label: '年',  },
        { value: 'month' , label: '月', }
    ],
    profit_statement_container_type : [
        { value:"0", label:'自拼' },
        { value:"1", label:'整柜' },
        { value:"2", label:'非拼' },
    ]
}

export default {
    dictListMap
}
